import PropTypes from 'prop-types';

import {
  rawRubricFetcher,
  rawTopicsFetcher,
} from 'core/fetchers/rubric';

import resolve from 'core/resolver/resolve';

import withPageHocs from 'core/components/withPageHocs';
import { withBreakpoint } from 'core/components/breakpoint';

import H1 from 'core/components/H1';
import Rubric from 'core/components/Rubric';
import Page from 'core/components/Page';
import EmptyWrapper from 'core/components/EmptyWrapper';
import { Indent } from 'core/components/Wrappers';

import { isGameCompare } from 'core/components/GameCompare/utils';

import { WideWrapper } from 'site/components/Wrappers';
import RubricTopics from 'site/components/RubricTopics';
import Boroda from 'site/components/Boroda';
import Pagination from 'site/components/Pagination';

const ARTICLES_PATHNAME = '/articles';

function RubricPage({  rawRubric, rawTopics, isDesktop, location }) {
  const {
    pathname,
  } = location;
  const isGameComparePage = isGameCompare(pathname);
  const isArticlesPage = pathname.indexOf(ARTICLES_PATHNAME) === 0;
  const MetaWrapper = isGameComparePage ? Page : EmptyWrapper;

  return (
    <>
      <Indent top={30} bottom={20}>
        <Rubric
          rawRubric={rawRubric}
          rawTopics={rawTopics}
          limit={isDesktop ? 16 : 9}
          headerSpacing={isDesktop ? 30 : 20}
          titleComponent={({ children }) => (
            <WideWrapper>
              <H1 dataQa='page-title'>{children}</H1>
            </WideWrapper>
          )}
          skipRcm
          pagination={props => (
            <WideWrapper>
              <Pagination {...props} />
            </WideWrapper>
          )}
        >
          {({ topics }) => (
            <MetaWrapper
              title='Противостояние «Кто кого?»'
              description='Какой Ганнибал Лектор лучше, Паддингтон или Винни Пух, какой фильм о Гарри Поттере лучший. Эти и другие противостояния на сайте OKKOLOKINO.'
              skipRcm
            >
              <RubricTopics
                topics={topics}
                isGameComparePage={isGameComparePage}
                isArticlesPage={isArticlesPage}
              />
            </MetaWrapper>
          )}
        </Rubric>
      </Indent>
      <Boroda />
    </>
  );
}

RubricPage.propTypes = {
  rawRubric: PropTypes.object,
  rawTopics: PropTypes.object,
  isDesktop: PropTypes.bool,
  location: PropTypes.object,
};

const dataProvider = resolve({
  rawRubric: rawRubricFetcher(),
  rawTopics: props => rawTopicsFetcher({
    limit: props.isDesktop ? 16 : 9,
    fields: 'list_headline,headline,published_at,link',
    include: isGameCompare(props.location.pathname) ? 'content' : 'image',
  })(props),
});

export default withBreakpoint(withPageHocs(dataProvider)(RubricPage));
