import { get } from 'core/libs/lodash';
import pluralize from 'core/utils/pluralize';
import likhieStaticCover from './likhie.jpg';
import { SELECTIONS_SLUG } from 'site/constants';


/**
 * Возвращает url указанной версии аватара.
 * @param {Object} [person={}] - объект, соответствующий модели person swagger-схемы
 * @param {string} [version=original] - тип аватара, который нужно получить
 * @returns {Object} Объект аватара указанной версии или аватар-заглушка.
 */
export function getAvatar(person = {}, version = 'original') {
  const path = [
    'attributes',
    'avatar',
    'attributes',
    'versions',
    version,
  ];

  const defaultAvatar = {
    rel_url: require('site/images/avatar.png'),
    width: 400,
    height: 400,
  };

  return get(person, path, defaultAvatar);
}


/**
 * Утилита форматирует строку "продолжительность фильма / сериала".
 *
 * @param {Object} movieMeta
 * @param {string|number} movieMeta.duration - длительность фильма
 * @param {number} movieMeta.seasonsCount - длительность сериала в сериях
 * @param {number} movieMeta.episodesCount - количество серий в многосерийном фильме
 * @returns {string}
 */
export const getMovieDurationWithSuffix = (movieMeta = {}) => {
  const { duration, seasonsCount, episodesCount } = movieMeta;

  if (duration) {
    if (typeof duration === 'number') {
      return Math.floor(duration / 60) + '\u00A0мин.';
    }

    return duration;
  }

  if (seasonsCount) {
    return `${seasonsCount}\u00A0${pluralize(seasonsCount, ['сезон', 'сезона', 'сезонов'])}`;
  }

  if (episodesCount) {
    return `${episodesCount}\u00A0${pluralize(episodesCount, ['серия', 'серии', 'серий'])}`;
  }

  return '';
};


/**
 *
 * @param {string} str строка с символами `\n`
 * @param {number} itemsLimit количество элементов, которые нужно оставить в
 *                            строке-результате
 * @param {*} overflowSuffix суффикс, который надо добавить строке, если кол-во
 *                           элементов превысило itemsLimit
 * @returns {string} строка с элементами, разделёнными запятыми
 */
export const replaceNewLineWithComma = (str, itemsLimit, overflowSuffix = '') => {
  if (typeof str !== 'string') return '';

  const items = str.split('\n', itemsLimit);
  const suffix = items.length > itemsLimit ? overflowSuffix : '';

  return items.join(', ') + suffix;
};

export function imageVersionExists(cover) {
  if (cover && cover.width && cover.height && cover.rel_url) return true;
  return false;
}

/**
 * @typedef {Object} TopicData
 * @property {Object[]} widgets обработанные виджеты топика
 * @property {Object} moviesData данные для рендера кнопок "смотреть"
 * @property {Object[]} movies фильмы, встречающихся в тизерах топика
 */
/**
 * Формирует данные для оглавления, отображения кнопок "смотреть"
 * в разрывах страниц и тизерах, автоматически добавляет разрыв
 * страницы после последнего фильма в подборках
 *
 * @param {Object[]} [initialWidgets=[]] виджеты топика
 * @param {string} [rubricSlug] слаг рубрики
 * @param {Object[]} [movies=[]] денормализованные топики-фильмы
 * @returns {TopicData}
 */
export function initTopicCtx(initialWidgets = [], rubricSlug, movies = []) {
  const widgets = [...initialWidgets];
  const moviesData = {};
  const isSelection = rubricSlug === SELECTIONS_SLUG;

  const teasersAndBreaks = widgets.filter(({ type }) =>  ['pageBreak', 'smartTeaser'].includes(type));
  /**
   * Редакции неудобно добавлять пустой виджет "Разрыв страницы" в конце топика
   * для того, чтобы у последнего фильма отображалась кнопка "Смотреть".
   * Делаем это программно.
   */
  if (isSelection) {
    const lastSmartTeaserIndex = widgets.map(({ type }) => type).lastIndexOf('smartTeaser');
    const hasPageBreak = widgets.slice(lastSmartTeaserIndex).some(({ type }) => type === 'pageBreak');

    if (!hasPageBreak) {
      widgets.push({
        'id': 'pageBreak-last',
        'type': 'pageBreak',
        'attributes': {
          'title': '',
          'caption': '',
        },
      });
    }
  }

  // Собираем инфу для содержания, pageBreak и SmartTeaser,
  // и формируем ссылки на фильм для кнопки "смотреть"

  let movie = null;
  let pageBreakNumber = 1;

  const processPageBreak = (widget) => {
    const nextTeaserIndex = teasersAndBreaks.findIndex(({ id }) => id === widget.id) + 1;
    const nextTeaser = teasersAndBreaks[nextTeaserIndex];
    const hideNumber = nextTeaser?.type !== 'smartTeaser';

    moviesData[widget.id] = {
      number: pageBreakNumber,
      movie,
      hideNumber,
    };
    movie = null;
    pageBreakNumber++;
  };

  const processSmartTeaser = (widget) => {
    const teaserTopicId = widget.attributes.teaser_params.topic_id;
    const widgetMovie = movies.find(movieItem => movieItem?.id === teaserTopicId);
    moviesData[widget.id] = {
      movie: widgetMovie,
    };
    movie = widgetMovie;
  };

  for (const widget of widgets) {
    if (widget.type === 'pageBreak') {
      processPageBreak(widget);
    }

    if (widget.type === 'smartTeaser') {
      processSmartTeaser(widget);
    }
  }

  return { widgets, moviesData, movies };
}

export const convertTopicToLikhiePromo = (topic, coverType) => {
  topic.attributes.headline = 'Всё главное о сериале «Лихие»';
  topic.attributes.link = 'https://blog.okko.tv/likhie';
  topic.attributes.published_at = '2024-10-29T07:00:30.000Z';
  topic.relationships.image.data.attributes.versions[coverType].rel_url = likhieStaticCover;
  delete topic.relationships.rubric;

  return topic;
};
