import { useContext } from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import Divider from 'core/components/Divider';

import { TopicContext } from 'site/pages/topic';

import modelPropTypes, { movieAttributes } from 'site/utils/prop-types/service-entities';
import { TABLE_OF_CONTENTS_TITLE } from 'site/constants';


function TableOfContents({ theme }) {
  const { movies } = useContext(TopicContext);
  if (!movies || movies.length < 1) return null;

  return (
    <nav className={'contentsTable'}>
      <style jsx>{`
        .link
          color ${theme.colors.accent}
          &:hover
            color ${theme.colors.primary}
      `}</style>
      <style jsx>
        {`
          .contentsTable
            margin-bottom 20px

          .title
            font-size 22px
            margin-bottom 20px

          .list
            margin 0
            padding 0

          .item
            margin-bottom 20px
            list-style none

          .link
            text-decoration none

          .itemNumber
            text-decoration none

          .itemText
            text-decoration underline dashed
            text-underline-offset 0.2em
            &:hover
              text-decoration none
        `}
      </style>
      <div className={'title'}>{TABLE_OF_CONTENTS_TITLE}</div>
      <ol className={'list'}>
        {movies.map((movie, index) => {
          const displayIndex = index + 1;
          return movie && (
            <li className={'item'} key={index}>
              <a className={'link'} href={`#${displayIndex}`}>
                <span className={'itemNumber'}>{`${displayIndex}. `}</span>
                <span className={'itemText'}>{movie.attributes.headline}</span>
              </a>
            </li>
          );
        })}
      </ol>
      <Divider />
    </nav>
  );
}

TableOfContents.propTypes = {
  movies: PropTypes.arrayOf(modelPropTypes(movieAttributes)),
  theme: PropTypes.object,
};

export default withTheme(TableOfContents);
