import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import skip from 'core/resolver/skip';
import { withBreakpoint } from 'core/components/breakpoint';
import { Helmet } from 'core/libs/helmet';

import ThemeSwitch from 'core/components/ThemeSwitch';

import AboutSeries from './AboutSeries';
import Shapka from './Shapka';
import Era from './Era';
import PhotoInterview from './PhotoInterview';
import TwoBlocks from './TwoBlocks';
import Interview from './Interview';
import Comments from './Comments';
import Heroes from './Heroes';

import { host } from 'site/constants';

import webPageSchema from './marking/webPageSchema';
import tvSeries from './marking/tvSeries';

import styles from './index.styl';

const likhieImage = require('./images/title.png');

const title = 'Сериал «Лихие» - новая криминальная драма от Юрия Быкова';
const description = 'Лихие — новый сериал режиссёра Юрия Быкова, переносящий зрителей в лихие 90-е, где жизнь полна риска и драматичных поворотов. Яркие герои раскрывают атмосферу времени, наполненного криминалом, опасными связями и борьбой за выживание.';

function Likhie({ isMobile }) {
  const [height, setHeight] = useState(null);

  useEffect(() => {
    const aboutSeriesEl = document.getElementById('about-series-desktop');
    if (!aboutSeriesEl) return;

    setHeight(aboutSeriesEl.offsetHeight + aboutSeriesEl.offsetTop);
  }, []);


  const ogImage = `https://${host}/icons/og-image-likhie.jpg`;

  return (
    <ThemeSwitch name='dark'>
      <Helmet
        meta={[
          { property: 'og:title', content: title },
          { property: 'og:description', content: description },
          { property: 'og:image', content: ogImage },
          { property: 'og:type', content: 'article' },
          { property: 'og:title', content: 'Сериал «Лихие» - новая криминальная драма от Юрия Быкова' },
          { property: 'og:description', content: 'Лихие — новый сериал режиссёра Юрия Быкова, переносящий зрителей в лихие 90-е, где жизнь полна риска и драматичных поворотов. Яркие герои раскрывают атмосферу времени, наполненного криминалом, опасными связями и борьбой за выживание.' },
          { property: 'og:url', content: 'https://blog.okko.tv/likhie' },
          { property: 'og:image', content: `https://${host}/${likhieImage}` },
          { property: 'og:site_name', content: 'ОККОЛОКИНО' },
          { property: 'og:locale', content: 'ru_RU' },
          { property: 'og:video', content: 'https://vk.com/video-47393707_456244538' },
          { property: 'og:video:type', content: 'text/html' },
          { property: 'og:video:width', content: '1280' },
          { property: 'og:video:height', content: '720' },
          { property: 'article:author', content: 'ОККОЛОКИНО' },
          { property: 'article:section', content: 'Криминальная драма' },
          { property: 'article:published_time', content: '2024-10-30T15:48:31+03:00' },
          { property: 'article:modified_time', content: '2024-10-30T15:48:31+03:00' },
          { property: 'article:tag', content: 'лихие 90-е' },
          { property: 'article:tag', content: 'сериалы о 90-х' },
          { property: 'article:tag', content: 'ОПГ' },
          { property: 'article:tag', content: 'Режиссёр Юрий Быков' },
          { property: 'article:tag', content: 'Артём Быстров' },
          { property: 'article:tag', content: 'Ксения Собчак' },
          { name: 'description', content: description },
        ]}
      >
        <title>{title}</title>
        <script type='application/ld+json'>{tvSeries}</script>
        <script type='application/ld+json'>{webPageSchema}</script>
      </Helmet>
      <div className={cx(styles.likhie)}>
        <div className={styles.animationBlocks} style={{ height }}>
          <div className={styles.container}>
            <Shapka />
          </div>
        </div>
        <AboutSeries />
        <Heroes />
        <Era />
        <PhotoInterview />
        <div className={styles.iframe}>
          <iframe
            width='100%'
            height={isMobile ? '202' : '765'}
            src='https://www.youtube.com/embed/t4pl9dPzz5k?si=_pHjMszmEHYXCu23'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerPolicy='strict-origin-when-cross-origin'
            allowFullScreen
          />
        </div>
        <Interview />
        <TwoBlocks />
        <Comments />
      </div>
    </ThemeSwitch>
  );
}

Likhie.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(skip(Likhie));
