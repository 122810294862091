import PropTypes from 'prop-types';

import GridFeed from 'core/components/GridFeed';
import Divider from 'core/components/Divider';

import { Indent } from 'core/components/Wrappers';

import bindProps from 'core/components/bindProps';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import H2Main from 'site/components/H2Main';
import WideWrapper from 'site/components/Wrappers/WideWrapper';

import CardVertical from 'site/cards/CardVertical';
import CardHorizontal from 'site/cards/CardHorizontal';
import { convertTopicToLikhiePromo } from 'site/utils';

import styles from './index.styl';


const CardFirstFourMobile = bindProps({
  size: 's',
  imageMaxWidth: 480,
  imageWrapperWidth: '480px',
  lockImageWidth: true,
})(CardVertical);

const CardFirstFourDesktop = bindProps({
  size: 's',
  imageMaxWidth: 320,
  imageWrapperWidth: '320px',
  lockImageWidth: true,
})(CardVertical);

const CardOthersMobile = bindProps({
  size: 'xs',
  imageMaxWidth: 60,
  imageWrapperWidth: '60px',
  lockImageWidth: true,
  imageVersion: 'square',
})(CardHorizontal);

const CardOthersDesktop = bindProps({
  size: 's',
  imageMaxWidth: 120,
  imageWrapperWidth: '120px',
  lockImageWidth: true,
  imageVersion: 'square',
})(CardHorizontal);


export default function ReadingNow(props) {
  const {
    topics,
    isMobile,
  } = props;

  if (!topics.length) return null;

  // https://jira.rambler-co.ru/browse/UCMS-13050
  const updatedTopics = [
    ...topics.slice(0, 1),
    convertTopicToLikhiePromo(topics[1], 'original'),
    ...topics.slice(2),
  ];
  const firstFour = updatedTopics.slice(0, 4);
  const others = updatedTopics.slice(4);

  return (
    <>
      <Divider className={styles.topDivider} />
      <H2Main>Сейчас читают</H2Main>
      <Indent bottom={isMobile ? 20 : 30} />
      <WideWrapper>
        <GridFeed
          content={firstFour}
          card={isMobile ? CardFirstFourMobile : CardFirstFourDesktop}
          gap={isMobile ? '20px' : '30px'}
          {...!isMobile && { gridTemplateColumns: 'repeat(4, 1fr)' }}
        />
        <Indent top={isMobile ? 34 : 30} />
        <GridFeed
          content={others}
          card={isMobile ? CardOthersMobile : CardOthersDesktop}
          gap={isMobile ? '20px' : '30px'}
          {...!isMobile && { gridTemplateColumns: 'repeat(3, 1fr)' }}
        />
      </WideWrapper>
    </>
  );
}

ReadingNow.propTypes = {
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  isMobile: PropTypes.bool,
};
